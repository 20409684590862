import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const JoinPage = () => (
  <Layout>
    <SEO title="Join" />
    <section className="intro">
      <div className="intro-container">
        <h2>Join</h2>
        <p>We welcome <strong>all genders</strong>, <strong>all (gray) sexualities</strong>, and <strong>all Asian heritages</strong>. ALKA takes privacy seriously. To join our community, <strong>all you need is a Discord account</strong>.</p>
      </div>
    </section>
    <section className="content">
      <div className="content-container">
        <p>Please take the following steps for an invite:</p>
        <ul>
          <li>Please share in 3-4 sentences via <Link to="/contact">our contact form</Link> how your Asian heritage has impacted your experience in the kink, fetish, and BDSM community.</li>
          <li>Please include a link to one social media or fetish profile on a site such as Twitter, Instagram, FetLife, or Recon.</li>
        </ul>
        <p>If accepted, a member of our executive team will reach out to you with an invite link.</p>
        <p>In solidarity,<br />ALKA Executive Team</p>
        <p><Link to="/" className="call-to-action">Go back home</Link></p>
      </div>
    </section>
  </Layout>
)

export default JoinPage
